import initSchemaLogger from '@wix/bi-logger-photo-ugc';
import { utils } from '../utils/workerUtils';
import { GALLERY_CONSTS } from 'pro-gallery-lib';

export default class BiLogger {
  constructor(biLoggerFactory, viewMode, isStore) {
    try {
      this.logger = initSchemaLogger(biLoggerFactory)();
    } catch (e) {
      console.log('BI Failed to init');
    }

    this.galleryLogData = {
      galleryType: isStore ? 'store' : 'progallery',
    };
    this.viewMode = viewMode;

    this.log = this.log.bind(this);
  }

  log(galleryId, funcName, data = {}) {
    if (
      this.viewMode !== GALLERY_CONSTS.viewMode.SITE ||
      utils.isDev() ||
      utils.isTest()
    ) {
      return;
    }
    try {
      const initialData = {
        ...this.galleryLogData,
        gallery_id: galleryId,
      };
      const logData = { ...initialData, ...data };
      if (this.logger[funcName]) {
        this.logger[funcName](logData);
      }
    } catch (e) {
      console.log('Error bi logger', e);
    }
  }
}
